import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

import Front from './front';

const Index = (props) => {
  const history = useHistory();
  const location = useLocation();
  
  function handleGoRoute() {
    if(location?.state?.from) {
      history.push(location.state.from)
    } else {
      history.push("/app/playlist")
    }
  }
  return (
    <Front
      onOkClick={handleGoRoute}
      teste={props.teste}
    />
  )
}

export default Index;
