import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../services/history';
import { getStoredInfos } from '../../utils/getStoredInfos';
import { useInterval } from '../../utils/useInterval';

import Front from './front';

import { Actions } from '../../store/modules/preferences/actions';
import { Actions as LikeActions } from '../../store/modules/likes/actions';
import { Actions as ProfileActions } from '../../store/modules/profile/actions';
import { Actions as CampaignActions } from '../../store/modules/campaigns/actions';

import defaultImage from "../../assets/images/default.jpg";

const Index = (props) => {
  const dispatch = useDispatch();

  const [currentUserIndex, setCurrrentUserIndex] = useState(0);
  const [nextUserIndex, setNextUserIndex] = useState(1);

  const [userLikedId, setUserLikedId] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const withAddress = true;
    const included = 'included=musical_preferences'
    dispatch(ProfileActions.loadProfileRequest(included))
    dispatch(Actions.loadUserPreferencesRequest(withAddress, currentPage))
    dispatch(CampaignActions.checkCampaignStatus())
  }, []);

  useInterval(() => {
    dispatch(ProfileActions.loadMessagesResumeRequest())
  }, 20000);

  const { preferences } = useSelector(state => state.userPreferences)
  const { image } = useSelector(state => state.profile)
  const { match } = useSelector(state => state.likes)

  const handleChangeIndex = () => {
    setCurrrentUserIndex(nextUserIndex)
    setNextUserIndex(nextUserIndex + 1)
  }

  const like = (userId) => {
    setUserLikedId(userId)
    dispatch(LikeActions.likeRequest(userId))

    if(!preferences[currentUserIndex + 5]) {
      dispatch(Actions.loadMoreUserPreferencesRequest(true, currentPage + 1))
      setCurrentPage(prev => prev + 1)
    }
  }

  const dislike = (userId) => {
    dispatch(LikeActions.dislikeRequest(userId))

    if(!preferences[currentUserIndex + 5]) {
      dispatch(Actions.loadMoreUserPreferencesRequest(true, currentPage + 1))
      setCurrentPage(prev => prev + 1)
    }
  }

  const sendMessage = () => {
    dispatch(LikeActions.resetMatch())
    history.push('/app/chat')
  }

  const continueMatch = () => {
    dispatch(LikeActions.resetMatch())
  }

  const getImages = () => {
    let images = []

    const stored = getStoredInfos()
    
    
    const { profile } = stored
    const { avatar } = profile.data.attributes
    
    const liked = preferences.find(item => item.id === userLikedId)
  
    images.push(avatar)
    images.push(liked?.image)

    return images;
  }

  const likeArtist = (id, combine) => {
    combine ? dispatch(Actions.dislikeUriRequest(id, currentUserIndex, 'artist')) : 
      dispatch(Actions.likeUriRequest(id, currentUserIndex, 'artist'))
  }

  const likeMusic = (id, combine) => {
    combine ? dispatch(Actions.dislikeUriRequest(id, currentUserIndex, 'music')) : 
      dispatch(Actions.likeUriRequest(id, currentUserIndex, 'music'))
  }

  console.log("Match => ", preferences)

  return (
    <Front
      userData={preferences[currentUserIndex]}
      userImage={image}
      nextUserData={preferences[nextUserIndex]}

      onLikeClick={like}
      onDislikeClick={dislike}

      onFinishAnimation={handleChangeIndex} /* nessa hora precisa trocar a informação */

      match={ match ? getImages() : false}

      onSendMessage={sendMessage}
      onContinue={continueMatch}

      onOpenChat={sendMessage}

      onLikeArtist={likeArtist}
      onLikeMusic={likeMusic}
    />
  )
}

export default Index;