import { select, all, call, takeLatest, put } from 'redux-saga/effects';
import { Campaign } from '../../../services/api/campaign';
import history from '../../../services/history';

import { Types, Actions } from './actions';
import { Actions as AuthActions } from '../auth/actions';

function* loadCurrentCampaign({ payload }) {
  const { slug } = payload;

  try {
    const response = yield call(Campaign.load, slug)
    const campaign = response.data.data

    const { active } = campaign.attributes

    if(!active) {
      history.push('/app/end')
    }

    const { color } = campaign.attributes
    document.documentElement.style.setProperty("--color-primary", color);

    if(color !== localStorage.getItem('campaign_color')) {
      localStorage.setItem('campaign_color', color)
    }

    yield put(Actions.loadCurrerntCampaignSuccess(campaign))
  } catch(err) {
    const { response } = err
    const { status } = response?.data?.errors

    if(status === 404) {
      history.push('/app/404')
    }

    yield put(Actions.loadCurrerntCampaignFailed(err))
  }

}

function* checkCampaignStatus() {
  const { currentCampaign } = yield select(state => state.auth);

  try {
    const response = yield call(Campaign.load, currentCampaign)
    const campaign = response.data.data
    
    const { color } = campaign.attributes
    document.documentElement.style.setProperty("--color-primary", color);

    if(color !== localStorage.getItem('campaign_color')) {
      localStorage.setItem('campaign_color', color)
    }

    const { active } = campaign.attributes

    if(!active) {
      yield put(AuthActions.accessDenied())
      history.push('/app/end')
    }

  } catch(err) {
    const { response } = err
    const { status } = response?.data?.errors

    if(status === 404) {
      yield put(AuthActions.accessDenied())
      history.push('/app/404')
    }
  }

}

export default all([
  //TODO: Remover
  // takeLatest(Types.LOAD_CURRENT_CAMPAIGN_REQUEST, loadCurrentCampaign),
  // takeLatest(Types.CHECK_CAMPAIGN_STATUS, checkCampaignStatus),
])