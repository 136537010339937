import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";


import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Actions as ProfileActions } from '../../store/modules/profile/actions';
import { Preferences } from '../../services/api/prerefences';

import Front from "./front";

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileReducer = useSelector(state => state.profile)

  const [formData, setFormData] = useState(null);
  const [loadingForm, setLoadingForm] = useState(true);
  const [selectedMusicals, setSelectedMusicals] = useState(profileReducer?.topMusics ?? []);
  const [isLoading, setIsLoading] = useState(false);


  const normalizeGenre = (value) => {
    if (value === "female") return "m";
    if (value === "male") return "h";
    if (value === "non_binary") return "nb";
    if (value === "undefined") return "o";
  };

  function handleUpdatePlaylist(musics) {
    setSelectedMusicals(musics)
  }

  async function handlePlaylistSaveClick() {
    try {
      const body = selectedMusicals.map((music, index) => {
        return {
            kind: 1,
            uid: music.id,
            extra_info: JSON.stringify({
              music,
            }),
            youtube_id: music.youtube_id,
            position: index+1
        }
      })

      await Preferences.addMusicalPreferences({
        musical_preferences: body,
        update: true
      })

      toast.success('Playlist salva com sucesso.');
    
      history.push("/app/match")    
    } catch (error) {
      toast.error('Erro ao salvar Playlist.');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const included = 'included=musical_preferences,city,city.state';
      dispatch(ProfileActions.loadProfileRequest(included));
    }
    fetchData()
  }, []);

  useEffect(() => {
    setSelectedMusicals(profileReducer.topMusics)
  }, [profileReducer]);

  return (
    <Front
      setFormData={setFormData}
      genreOptions={[
        { value: "-", label: "- selecionar -" },
        { value: "m", label: "Mulher" },
        { value: "h", label: "Homem" },
        { value: "nb", label: "Não-binário" },
        { value: "o", label: "Outro" },
      ]}
      isLoading={isLoading}
      selectedMusicals={selectedMusicals}
      onPlaylistSaveClick={handlePlaylistSaveClick}
      updatePlaylist={handleUpdatePlaylist}
    />
  );
};

export default Index;
