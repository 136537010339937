export const extractArtistInfos = (data) => {
  const partials = data.extra_info.split(':image=>')
  const nameAndImg = partials[1].split(':name=>')
  
  const name = nameAndImg[1].substring(1, nameAndImg[1].length - 2)
  const image = nameAndImg[0].substring(1, nameAndImg[0].length - 3);

  return [name, image];
}

export const extractTrackInfos = (track) => {

  const extra_info = JSON.parse(track.extra_info);

  return [extra_info.music.name, extra_info?.music?.artist || extra_info?.music?.autor, extra_info.music.image];
}