import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';

import './config/ReactotronConfig';

import { Provider } from 'react-redux';
import { store, persistor }   from './store';

import './App.scss';

import { Router } from "react-router-dom";

import history from './services/history';

import Routes from './routes';

function App(props) {
  // ---> @valmy, aqui seleciona a cor primária.

  const themeColor = localStorage.getItem('campaign_color');
  if (themeColor) {
    document.documentElement.style.setProperty("--color-primary", themeColor);
  }
  // <---

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Toaster
              position="top-right"
              gutter={8}
              toastOptions={{
                duration: 3000,
                style: {
                  fontSize: 16
                },
                error: {
                  style: {
                    background: '#ff4b4b',
                    color: '#FFFAEE'
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#ff4b4b',
                  },
                },
                success: {
                  duration: 3000,
                  style: {
                    background: '#61d345',
                    color: '#FFFAEE'
                  },
                  iconTheme: {
                    primary: '#FFFAEE',
                    secondary: '#61d345',
                  },
                },
              }}
            />
            <Routes/>
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
