import React, { useEffect, useState } from 'react';

import defaultImage from "../../assets/images/default.jpg";
import playIcon from "../../assets/images/loginV3/play_icon.svg";
import heartFull from "../../assets/images/loginV3/heart_full.svg";
import star from "../../assets/images/loginV3/star_music.svg";
import starMusic from "../../assets/images/loginV3/star.svg";
import heart from "../../assets/images/loginV3/heart.svg";

function MusicList(props) {
  const musicas = [
    {
      id: '1',
      uid: '1',
      image: 'https://i.ytimg.com/vi/h09p0IGiKaE/hqdefault.jpg',
      name: 'Canudinho',
      autor: 'Gusttavo Lima',
      youtube_id: 'h09p0IGiKaE'
    },


    {
      id: '2',
      uid: '2',
      image: 'https://i.ytimg.com/vi/3zNabdrryYM/hqdefault.jpg',
      name: 'Mala dos Porta Mala ',
      autor: 'Gusttavo Lima',
      youtube_id: '3zNabdrryYM'
    },
    {
      id: '3',
      uid: '3',
      image: 'https://i.ytimg.com/vi/-UUe7g8-E0k/hqdefault.jpg',
      name: 'Desejo Imortal',
      autor: 'Gusttavo Lima',
      youtube_id: '-UUe7g8-E0k'
    },
    {
      id: '4',
      uid: '4',
      image: 'https://i.ytimg.com/vi/rTJSWmgbVwA/hqdefault.jpg',
      name: 'Termina Comigo Antes',
      autor: 'Gusttavo Lima',
      youtube_id: 'rTJSWmgbVwA'
    },
    {
      id: '5',
      uid: '5',
      image: 'https://i.ytimg.com/vi/D9sy95Cpcpg/hqdefault.jpg',
      name: 'Oi Vida',
      autor: 'Gusttavo Lima',
      youtube_id: 'D9sy95Cpcpg'
    },
    {
      id: '6',
      uid: '6',
      image: 'https://i.ytimg.com/vi/ARYMAKnI0pk/hqdefault.jpg',
      name: 'Oficializar',
      autor: 'Gusttavo Lima',
      youtube_id: 'ARYMAKnI0pk'
    },
    {
      id: '7',
      uid: '7',
      image: 'https://i.ytimg.com/vi/14C28JSO-sg/hqdefault.jpg',
      name: 'Bloqueado',
      autor: 'Gusttavo Lima',
      youtube_id: '14C28JSO-sg'
    },
    {
      id: '8',
      uid: '8',
      image: 'https://i.ytimg.com/vi/tlfY5NL7ZzA/hqdefault.jpg',
      name: 'Inesquecível',
      autor: 'Gusttavo Lima',
      youtube_id: 'tlfY5NL7ZzA'
    },
    {
      id: '9',
      uid: '9',
      image: 'https://i.ytimg.com/vi/XjoW45h0Hzw/hqdefault.jpg',
      name: 'Compensa',
      autor: 'Gusttavo Lima',
      youtube_id: 'XjoW45h0Hzw'
    },
    // {
    //   id: '10',
    //   uid: '10',
    //   image: 'https://i.ytimg.com/vi/14C28JSO-sg/hqdefault.jpg',
    //   name: 'Bloqueado',
    //   autor: 'Gusttavo Lima',
    //   youtube_id: '14C28JSO-sg'
    // },
    {
      id: '11',
      uid: '11',
      image: 'https://i.ytimg.com/vi/zZiyCDUP5UQ/hqdefault.jpg',
      name: 'Mordidinha',
      autor: 'Gusttavo Lima',
      youtube_id: 'zZiyCDUP5UQ'
    },
    {
      id: '12',
      uid: '12',
      image: 'https://i.ytimg.com/vi/Jm5MCIVwjas/hqdefault.jpg',
      name: 'Saudade da Minha Vida',
      autor: 'Gusttavo Lima',
      youtube_id: 'Jm5MCIVwjas'
    },
    {
      id: '14',
      uid: '14',
      image: 'https://i.ytimg.com/vi/hOGuaMazCz8/hqdefault.jpg',
      name: 'Fala Mal de Mim',
      autor: 'Gusttavo Lima',
      youtube_id: 'hOGuaMazCz8'
    },
    {
      id: '15',
      uid: '15',
      image: 'https://i.ytimg.com/vi/NlGuszK4FZw/hqdefault.jpg',
      name: 'Adivinha',
      autor: 'Gusttavo Lima',
      youtube_id: 'NlGuszK4FZw'
    },
    {
      id: '16',
      uid: '16',
      image: 'https://i.ytimg.com/vi/Uo7U7ruedic/hqdefault.jpg',
      name: 'Na Hora de Amar',
      autor: 'Gusttavo Lima',
      youtube_id: 'Uo7U7ruedic'
    },
    {
      id: '17',
      uid: '17',
      image: 'https://i.ytimg.com/vi/KWp54ZJ2Q0E/hqdefault.jpg',
      name: 'Solteiro Frustrado',
      autor: 'Gusttavo Lima',
      youtube_id: 'KWp54ZJ2Q0E'
    },
    {
      id: '19',
      uid: '19',
      image: 'https://i.ytimg.com/vi/UIAXbSCjtwQ/hqdefault.jpg',
      name: 'Fora do Comum',
      autor: 'Gusttavo Lima',
      youtube_id: 'UIAXbSCjtwQ'
    },
    {
      id: '21',
      uid: '21',
      image: 'https://i.ytimg.com/vi/ZWlZR2HKIQA/hqdefault.jpg',
      name: 'Ficha Limpa',
      autor: 'Gusttavo Lima',
      youtube_id: 'ZWlZR2HKIQA'
    },
    {
      id: '23',
      uid: '23',
      image: 'https://i.ytimg.com/vi/d7NMHcIecCE/hqdefault.jpg',
      name: 'Ex dos Meus Sonhos',
      autor: 'Gusttavo Lima',
      youtube_id: 'd7NMHcIecCE'
    },
    {
      id: '24',
      uid: '24',
      image: 'https://i.ytimg.com/vi/ilvBKB20Rjk/hqdefault.jpg',
      name: 'Temporal de Amor / Não Olhe Assim Não',
      autor: 'Gusttavo Lima',
      youtube_id: 'ilvBKB20Rjk'
    },

]

  const [idsSelectedMusicals, setIdsSelectedMusicals] = useState([]);
  const [selectedMusicals, setSelectedMusicals] = useState([]);

  function handleMusicalSelected(music) {
    if(idsSelectedMusicals.includes(music.id)) {
      const ids = idsSelectedMusicals.filter(ids => ids !== music.id);
      const musicalsFiltered = selectedMusicals.filter(item => item.uid !== music.id);

      setIdsSelectedMusicals(ids);
      setSelectedMusicals(musicalsFiltered);  

      props.updatePlaylist(musicalsFiltered);
    } else {
      const ids = [
        ...idsSelectedMusicals,
        music.id
      ]

      const musicals = [
      ...selectedMusicals,
        music
      ]

      setIdsSelectedMusicals(ids)
      setSelectedMusicals(musicals)
      props.updatePlaylist(musicals);
    }

  }

  useEffect(() => {
    function init(){

      const musicalsData = props.data ?? [];

      const musicalsIds = musicalsData?.map(music => music?.uid);

      setSelectedMusicals(musicalsData);
      setIdsSelectedMusicals(musicalsIds)
    }
    init();
  },[props.data])

  return (
    <>
      <div className={`music-header ${props.header ? 'match-header' : ''}`}>
        <img
          id="playIcon"
          src={playIcon}
          alt="Play Icon"
          title="Play Icon"
        />

        {props.title ? 
          <div className="music-header-title">
            <span>{props.title}</span>
            {props.subtitle ? 
              <span className="info">
                <img
                  src={star}
                  alt='Estrela'
                />
                {' '}Músicas em comum
                </span> 
            : ''} 
          </div>
        :
          <div className="music-header-title">
            <span>Monte sua playlist: *</span>
            <span className="info">*Escolha ao menos 3 músicas.</span>
          </div>
        }
        
      </div>
      <div className="music-content">
        {musicas.map((musica) => (
          <div className="music-info" key={musica.id}>
            <div className="music-image"> 
              <div
                className='image'>
                <img
                  id={musica.id}
                  src={musica.image}
                  alt={musica.name}
                  />
              </div>
              <div className="text-info">
                <div className="title">{musica.name}</div>
                <div className="author">{musica.artist}</div>
              </div>
            </div>
            {props.star ? 
              idsSelectedMusicals?.includes(String(musica.id)) ? (
                <div className="star">
                  <img
                    id={musica.id}
                    src={starMusic}
                    alt={musica.name}
                  />
                </div> 
              )
              : 
              ''
            : 
            <div className="icon" onClick={() => handleMusicalSelected(musica)}>
              <img
                id={musica.id}
                src={idsSelectedMusicals?.includes(String(musica.id)) ? heart : heartFull   }
                alt={musica.name}
              />
            </div> }
            
          </div>
        ))}
      </div>
    </>
  );
}

export { MusicList };
