import React, {useState} from 'react';
import {Page, Container, AccountForm, LoginLayout, Wrapper} from '../../components';

function Front(props) {
  const [localData, setLocalData] = useState(props.formData)
  const [imageData, setImageData] = useState()

  return (
      // props.teste ?
        <Page name="create-account-gusttavo" loading={props.loadingForm}>
          <div className='content'>
            <Container center>
              <Wrapper className="mt-lg">
                <AccountForm
                  saveButtonLabel="Salvar"
                  data={localData}
                  setData={setLocalData}
                  imageData={imageData}
                  setImageData={setImageData}
                  statesOptions={props.statesOptions}
                  citiesOptions={props.citiesOptions}
                  genreOptions={props.genreOptions}
                  disableCities={props.disableCities}

                  onChangeState={props.onChangeState}
                  onSaveClick={() => props.onSaveClick({...localData, ...imageData})}
                  teste='teste'
                />
              </Wrapper>
            </Container>
          </div>
        </Page>
      //  :
      //   <Page name="create-account" loading={props.loadingForm}>
      //     <LoginLayout coverImagesSrc={props.coverImagesSrc}>
      //       <div className="create-account-form">
      //         <h3 className="text-center">Vamos criar o seu perfil</h3>
      //         <AccountForm
      //           saveButtonLabel="Continuar"
      //           data={localData}
      //           setData={setLocalData}
      //           imageData={imageData}
      //           setImageData={setImageData}
      //           statesOptions={props.statesOptions}
      //           citiesOptions={props.citiesOptions}
      //           genreOptions={props.genreOptions}
      //           disableCities={props.disableCities}

      //           onChangeState={props.onChangeState}
      //           onSaveClick={() => props.onSaveClick({...localData, ...imageData})}
      //         />
      //       </div>
      //     </LoginLayout>
      //   </Page>
  );
}

export default Front;
