import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {Page, Divider, Container, Button, Grid, Image, Icon, Wrapper} from '../../components';

function Front(props) {
  return (
    <Page name="tutorial">
      <Container center>
        <Wrapper>
            <h1 className="h3 text-center mt-0 pt-7_md pt-5 mb-5">Como funciona</h1>
            <div>
            {
              [
                {title:'Curtir', icon:'heart-filled', text:'Utilize esse botão para perfis que você curtiu.'},
                {title:'Passar', icon:'close', text:'Utilize esse botão para perfis que você não curtiu.'},
                {title:'Compatibilidade músical', icon:'music', text:'A barrinha mostra o quanto seu gosto musical é parecido com o da outra pessoa.'},
                {title:'Match', icon:'heart-filled', text:'Se você e a pessoam se curtirem, o chat é habilitado para que vocês possam conversar.'},
              ].map((item, index) => {
                return (
                <div key={index} className={ props.teste === 'teste' ? 'tutorial-item-gusttavo mb-3 mb-5_md' : 'tutorial-item mb-3 mb-5_md' }>
                  <Icon name={item.icon}/>
                  <div>
                    <b>{item.title}</b>
                    <span>{item.text}</span>
                  </div>
                </div>
                )
              })
            }
            </div>
            <Button className={ props.teste === 'teste' ? 'button-gusttavo full mt-4' : 'primary full mt-4' } onClick={() => props.onOkClick()}>Ok</Button>
        </Wrapper>
      </Container>
    </Page>
  );
}

export default Front;
