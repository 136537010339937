import React, {Fragment, useState} from 'react';
import {Page, Container, Button, ProfileCard, Navbar, Divider, Image, Modal, Grid, Icon, Wrapper, utils} from '../../components';
import imageMatch from '../../assets/images/match.svg';
import noMatch from '../../assets/images/loginV3/no_match.svg';
import { useSelector } from 'react-redux';
import imageMatchGusttavo from '../../assets/images/loginV3/match.png';
import defaultImage from '../../assets/images/default.jpg';

import {Link} from 'react-router-dom'

function Front(props) {

  const onFinishAnimation = () => {
    setInAnimation('in-animation');
    utils.timeout(() => {
      props.onFinishAnimation();
      setInAnimation('');
    }, 1000);
  }

  const [inAnimation, setInAnimation] = useState('');
  const [isLike, setIsLike] = useState(false);
  const { currentCampaign } = useSelector(state => state.auth)

  return (
    <Page name="match-gusttavo" className={`${inAnimation} ${isLike?'is-like':'is-dislike'} ${props.userData?'':'is-empty'} ${props.nomatch ? 'no-match' : ''}`} loading={props.loadingForm}> 
    {/* pagina que mostra as pessoas pro like */}
      <Navbar chatCount={5} menuHighlighter="match" teste='teste' />
      <Container center>
        {props.userData &&
          <Fragment>
            <Grid>
              <Grid.cell className="cell-start1 cell-end-12 cell-start-2_lg cell-end-11_lg cell-start-3_md cell-end-10_md">
                <div className="profile-cards">
                  {props.userData &&
                    <ProfileCard userData={props.userData} onLikeMusic={(id, combine) => props.onLikeMusic(id, combine)} onLikeArtist={(id, combine)=> props.onLikeArtist(id, combine)}/>
                  }
                  {props.nextUserData &&
                    <ProfileCard userData={props.nextUserData} />
                  }
                </div>

              </Grid.cell>
            </Grid>
            <div className="match-actions">
              <Button className="circled button-dislike" onClick={() => {
                props.onDislikeClick(props.userData.id);
                onFinishAnimation();
                setIsLike(false);
              }}><Icon name="close" /></Button>

              <Button className="circled button-like" onClick={() => {
                props.onLikeClick(props.userData.id);
                onFinishAnimation();
                setIsLike(true);
              }}><Icon name="heart-filled" /></Button>

            </div>
          </Fragment>
        }

        {/* {!props.userData ? /* pagina onde ja viu todos os matchs */}
        {!props.userData ? /* pagina onde ja viu todos os matchs */
          <div className="match-empty">
              <Image src={noMatch} />
            <p className="text-center pt-3 text-bold mb-1">Você já viu todos os seus matchs :(</p>
            <p className="mt-0 text-center">Volte mais tarde para encontrar novas pessoas</p>
          </div>            
        : ''}
      </Container>
      <Modal show={props.match}> {/* modal do match */}
        <Modal.content className="modal-match" show={props.match} onClose={() => console.log()}>
          <Wrapper>
            <Image className="text-center mt-2 mb-4" src={imageMatchGusttavo} />
            <div className="avatars">
              {props.match &&
                <Fragment>
                  <Image src={props.match[0] || defaultImage} />
                  <Image src={props.match[1] || defaultImage}  />
                </Fragment>
              }
            </div>
            <div className="match-buttons">
              <Button className="message" onClick={() => props.onSendMessage()}>Enviar mensagem</Button>
              <Button className=" play" onClick={() => props.onContinue()}>Continuar jogando</Button>
            </div>
          </Wrapper>
        </Modal.content>
      </Modal>
    </Page>
  );
}

export default Front;
