import React from "react";
import { Page } from "../../components";

function PrivacyPolicy(props) {
  return (
    <Page name="politica-de-privacidade">
      <main style={{ padding: "60px" }}>
        <div className="container">
          <article className="politica-privacidade-fix">
            <div style={{ height: "60px" }} />
            <p>
              <h1>POLÍTICA DE PRIVACIDADE E COOKIES</h1>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <strong>Sony Music Entertainment Brasil Ltda.</strong> empresa
                com sede na Rua Lauro Müller, 116, 40º andar, Botafogo, Rio de
                Janeiro/RJ, CEP: 22290-160, inscrita no CNPJ sob o nº
                43.203.520/0001-04 (“Sony” ou “Nós”), quer explicar para Você,
                por meio desta política de privacidade e cookies (“Política”)
                como utilizamos seus dados pessoais no site{" "}
              </span>
              <a href="https://embaixadorcupido.com.br/">
                <span style={{ fontWeight: 400 }}>https://embaixadorcupido.com.br</span>
              </a>
              <span style={{ fontWeight: 400 }}> ( “Site”).</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A fim de descomplicar a sua leitura deste documento,
                apresentamos algumas definições úteis para a sua interpretação.
              </span>
            </p>
            <p>
              <i>
                <span style={{ fontWeight: 400 }}>Dado pessoal</span>
              </i>
              <span style={{ fontWeight: 400 }}>
                : é uma informação relacionada a uma pessoa física e que seja
                capaz de{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                identificar a pessoa ou tornar possível a sua identificação. São
                exemplos de dados pessoais que podem permitir a sua
                identificação: Nome, CPF, telefone, e-mail, placa do seu veículo
                etc.
              </span>
            </p>
            <p>
              <i>
                <span style={{ fontWeight: 400 }}>Tratamento</span>
              </i>
              <span style={{ fontWeight: 400 }}>
                : é toda forma de uso que podemos fazer dos seus Dados Pessoais,
                incluindo, mas não se limitando às seguintes atividades: coleta,
                armazenamento, consulta, uso,{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                compartilhamento, transmissão, classificação, reprodução,
                exclusão e avaliação.
              </span>
            </p>
            <p>
              <i>
                <span style={{ fontWeight: 400 }}>Titular</span>
              </i>
              <span style={{ fontWeight: 400 }}>
                : é você, a pessoa física a quem os dados pessoais se referem.
              </span>
            </p>
            <ol>
              <li>
                <b>
                  {" "}
                  Como seus dados pessoais são coletados e quais são os tipos de
                  dados{" "}
                </b>
                <b
                  style={{
                    fontSize: "revert",
                    color: "initial",
                    fontFamily:
                      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                  }}
                >
                  pessoais coletados?
                </b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                A quantidade e o tipo de informações coletadas pela Sony variam
                conforme o uso que o
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                usuário faz do Site. Todos os dados coletados ou fornecidos
                diretamente pelo titular dos dados pessoais são tratados como
                confidenciais, e somente os usaremos para os fins aqui descritos
                e/ou autorizados por Você.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Para saber mais sobre quais são os dados pessoais coletados e
                suas respectivas finalidades, consulte a tabela abaixo
                disponibilizada.
              </span>
            </p>
            <div className="wp-block-columns is-layout-flex wp-container-core-columns-layout-1 wp-block-columns-is-layout-flex">
              <div
                className="wp-block-column is-layout-flow wp-block-column-is-layout-flow"
                style={{ flexBasis: "100%" }}
              >
                <div className="wp-block-group is-layout-flow wp-block-group-is-layout-flow">
                  <div className="wp-block-group__inner-container">
                    <figure className="wp-block-table is-style-stripes">
                      <table>
                        <tbody>
                          <tr>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <p>
                                <b>Origem</b>
                                <span style={{ fontWeight: 400 }}>&nbsp;</span>
                              </p>
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <p>
                                <b>Tipo de Dados Coletados</b>
                                <span style={{ fontWeight: 400 }}>&nbsp;</span>
                              </p>
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <p>
                                <b>Finalidade</b>
                                <span style={{ fontWeight: 400 }}>&nbsp;</span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <br />
                              <br />
                              <br />
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                Navegação no&nbsp;Site
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                &nbsp;
                              </span>
                              <br />
                              <br />
                              <br />
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <br />
                              <br />
                              <p>
                                <b>Dados de navegação:&nbsp;</b>
                                <span style={{ fontWeight: 400 }}>
                                  dados coletados por meio de&nbsp;
                                </span>
                                <i>
                                  <span style={{ fontWeight: 400 }}>
                                    cookies&nbsp;
                                  </span>
                                </i>
                                <span style={{ fontWeight: 400 }}>
                                  ou&nbsp;
                                </span>
                                <i>
                                  <span style={{ fontWeight: 400 }}>
                                    device IDs
                                  </span>
                                </i>
                                <span style={{ fontWeight: 400 }}>
                                  , incluindo IP, data e hora de acesso,
                                  localização geográfica, tipo de navegador,
                                  duração da visita e páginas visitadas.
                                </span>
                              </p>
                              <p>
                                <span style={{ fontWeight: 400 }}>&nbsp;</span>
                                <b>Dados</b> <b>sobre</b>
                                <b>o&nbsp;dispositivo de acesso:&nbsp;</b>
                                <span style={{ fontWeight: 400 }}>
                                  modelo, fabricante, sistema operacional,
                                  operadora de telefonia, tipo de navegador,
                                  tipo e velocidade da conexão.&nbsp;
                                </span>
                                <span
                                  style={{
                                    fontFamily: "inherit",
                                    fontSize: "inherit",
                                    fontWeight: "inherit",
                                    color: "initial",
                                  }}
                                />
                              </p>
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <p>
                                <b>Registro de acesso:&nbsp;</b>
                                <span style={{ fontWeight: 400 }}>
                                  registrar o seu IP, data e hora de
                                  acesso.&nbsp;
                                </span>
                              </p>
                              <br />
                              <strong>Funcionamento do site:&nbsp;</strong>
                              ativar funcionalidades essenciais, como software
                              antivírus, apresentação do conteúdo na tela, entre
                              outras funções.&nbsp;
                              <br />
                              <br />
                              <strong>
                                <em>Analytics</em>:&nbsp;
                              </strong>
                              entender&nbsp;seu&nbsp;comportamento de navegação
                              e como o&nbsp;site está&nbsp;sendo&nbsp;usado,
                              para melhorar sua experiência como usuário. Os
                              dados coletados são&nbsp;agregados e, sempre
                              que&nbsp;possível, anonimizados.&nbsp;
                              <br />
                              <br />
                              <strong>Marketing:&nbsp;</strong>mostrar anúncios
                              on-line que podem ser&nbsp;do seu interesse, com
                              base nos dados relativos à sua navegação.&nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <br />
                              <br />
                              <br />
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                Formulário
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                  color: "initial",
                                }}
                              />
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                de&nbsp;contato dos nossos serviços
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                  color: "initial",
                                }}
                              />
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                de
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                &nbsp;
                              </span>
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                atendimento&nbsp;– fale conosco
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                  color: "initial",
                                }}
                              >
                                &nbsp;
                              </span>
                              <br />
                              <br />
                              <br />
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <br />{" "}
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                Dados de contato:&nbsp;
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                nome&nbsp;e&nbsp;e-mail. Demais informações
                                poderão ser inseridas
                                no&nbsp;campo&nbsp;“Mensagem”,
                                mas&nbsp;ficarão&nbsp;à&nbsp;critério&nbsp;do&nbsp;usuário.&nbsp;
                              </span>
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                Comunicação
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                : responder suas perguntas e atender às suas
                                solicitações, direcionar reclamações, aos
                                responsáveis, manter registro do seu histórico
                                de contato e seu cadastro atualizado, fornecer
                                atualizações a Você sobre mudanças nas
                                políticas, termos e condições&nbsp;e
                                quaisquer&nbsp;outras
                                questões&nbsp;&nbsp;&nbsp;&nbsp;que&nbsp;&nbsp;&nbsp;
                                precisem&nbsp; ser comunicadas a Você. Além de
                                coletar estatísticas e informações para realizar
                                estudos, treinamentos e tomar providências para
                                melhoria das nossas ações e iniciativas.&nbsp;
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <br />
                              <br />
                              <br />
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                Receber
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                &nbsp;
                              </span>
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                mailing&nbsp;e comunicações
                              </b>
                              <span
                                style={{
                                  fontSize: "inherit",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                  color: "initial",
                                }}
                              />
                              <br />
                              <br />
                              <br />
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <br />
                              <br />
                              <p>
                                <b>Dados de cadastro</b>
                                <span style={{ fontWeight: 400 }}>:&nbsp;</span>
                                <span
                                  style={{
                                    fontFamily: "inherit",
                                    fontSize: "inherit",
                                    fontWeight: "inherit",
                                    color: "initial",
                                  }}
                                />
                                <span style={{ fontWeight: 400 }}>
                                  Nome,&nbsp;e-mail&nbsp;e/ou&nbsp;celular&nbsp;
                                </span>
                              </p>
                            </td>
                            <td
                              data-align="center"
                              className="has-text-align-center"
                            >
                              <b
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                Marketing
                              </b>
                              <span
                                style={{
                                  fontSize: "revert",
                                  fontFamily: "inherit",
                                  color: "initial",
                                }}
                              >
                                : Enviar de forma ativa&nbsp;mailing e
                                comunicação sobre a Sony,
                                artistas,&nbsp;parceiros,&nbsp;bem como
                                suas&nbsp;iniciativas&nbsp;e projetos, por
                                celular, telefone ou pela internet (email,
                                mensagens diretas em redes sociais, mensagens em
                                aplicativos, como&nbsp;WhatsApp)&nbsp;
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <ol start={2}>
              <li>
                <b> Como utilizamos Cookies?</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                “Cookies” são identificadores que são transferidos para o seu
                navegador ou dispositivo, que nos informam como e quando as
                páginas e recursos do Site são visitados, quantas pessoas as
                acessam e algumas informações sobre seus dispositivos.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Quando um Usuário visita o Site, são inseridos “cookies” no seu
                navegador (p. ex. Firefox, Internet Explorer, Chrome, etc) que
                realizam a coleta automática de determinados dados pessoais para
                que possamos (i) operacionalizar nosso site (cookies
                estritamente necessários) e (ii) obter informações agregadas
                sobre nossos Usuários. O Site também possui (iii) cookies de
                terceiros que são utilizados para publicidade (cookies de
                publicidade/advertising).
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                O Usuário pode optar por recusar ou desabilitar os Cookies por
                meio das configurações do seu navegador, ou ao instalar um
                plug-in que realize essa funcionalidade. No entanto, ao fazer
                isso, algumas áreas do Site podem não funcionar corretamente.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Ainda, esta Política não cobre o uso de cookies por terceiros, e
                não somos responsáveis por suas políticas e práticas de
                privacidade.
              </span>
            </p>
            <ol start={3}>
              <li>
                <b> Com quem a Sony compartilha os seus dados pessoais?</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Algumas vezes, precisamos compartilhar os seus dados pessoais
                com terceiros que prestam serviços em nosso nome, principalmente
                no uso de serviços auxiliares ao funcionamento do Site.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Abaixo descrevemos algumas situações nas quais podemos
                compartilhar os seus dados{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                pessoais e, sempre que for possível, o faremos de forma
                anonimizada.
              </span>
            </p>
            <p>
              <b>Parceiros e fornecedores</b>
              <span style={{ fontWeight: 400 }}>
                : a Sony pode compartilhar Dados Pessoais que coleta sobre a
                partir do Site com parceiros e/ou fornecedores da Sony,
                incluindo{" "}
              </span>
              <i>
                <span style={{ fontWeight: 400 }}>Google Analytics </span>
              </i>
              <span style={{ fontWeight: 400 }}>e</span>
              <i>
                <span style={{ fontWeight: 400 }}>TikTok</span>
              </i>
              <span style={{ fontWeight: 400 }}>
                . Nossos parceiros e fornecedores somente são autorizados a
                utilizar os dados pessoais para os fins específicos que eles
                foram contratados, portanto, eles não irão utilizar os seus
                dados pessoais para outras finalidades além as da prestação dos
                serviços previstos contratualmente.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Caso tenha qualquer dúvida em relação a quais são os nossos
                fornecedores e parceiros,{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                entre em contato no canal de contato que se encontra abaixo.
              </span>
            </p>
            <p>
              <b>Autoridades Públicas</b>
              <span style={{ fontWeight: 400 }}>
                . Cumpriremos a lei. Assim, se um juiz ou uma autoridade com{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                competência legal exigir que a Sony compartilhe certos dados
                pessoais para, por exemplo, uma investigação, vamos
                compartilhar. Somos contra qualquer abuso de autoridade e, caso
                a Sony entenda que determinada ordem é abusiva, vamos sempre
                defender a privacidade dos titulares.
              </span>
            </p>
            <p>
              <b>Proteção de direitos</b>
              <span style={{ fontWeight: 400 }}>
                . Além disso, reservamo-nos o direito de compartilhar quaisquer
                dados pessoais que acreditarmos serem necessários para cumprir
                uma obrigação legal, aplicar nossos termos de uso ou, ainda,
                proteger os direitos da Sony e dos nossos colaboradores.
              </span>
            </p>
            <ol start={4}>
              <li>
                <b>
                  {" "}
                  Quais são os seus direitos como titular de dados
                  pessoais?&nbsp;
                </b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Os dados pessoais são seus e a lei brasileira garante que Você
                tenha uma série de direitos relacionados a eles. Nós estamos
                comprometidos com o cumprimento desses direitos e, nessa seção,
                vamos explicar como Você pode exercê-los junto à Sony. A lei
                brasileira lhe garante os seguintes direitos:
              </span>
            </p>
            <figure className="wp-block-table is-style-stripes">
              <table>
                <tbody>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <b>Confirmação e Acesso</b>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                        <span
                          style={{
                            fontFamily: "inherit",
                            fontSize: "inherit",
                            fontWeight: "inherit",
                            color: "initial",
                          }}
                        />
                      </p>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Permite que Você possa verificar se
                          a&nbsp;Sony&nbsp;trata dados pessoais seus e,
                          em&nbsp;caso&nbsp;positivo,&nbsp;requisitar&nbsp;uma&nbsp;cópia&nbsp;dos&nbsp;dados&nbsp;pessoais&nbsp;que&nbsp;nós&nbsp;temos&nbsp;sobre&nbsp;Você.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <b>Correção</b>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Permite que Você solicite a correção dos seus dados
                          pessoais incompletos, inexatos ou
                          desatualizados.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <b>Anonimização, bloqueio ou&nbsp;eliminação</b>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Permite&nbsp;que&nbsp;Você&nbsp;nos&nbsp;peça&nbsp;para&nbsp;(a)&nbsp;anonimizar&nbsp;seus&nbsp;dados,&nbsp;de&nbsp;forma&nbsp;a&nbsp;que&nbsp;eles
                          não possam mais ser relacionados a Você e, portanto,
                          deixem de ser dados pessoais; (b) bloquear seus dados,
                          suspendendo temporariamente a sua
                          possibilidade&nbsp;de&nbsp;tratarmos;&nbsp;e&nbsp;(c)&nbsp;eliminar&nbsp;seus&nbsp;dados,&nbsp;caso&nbsp;em&nbsp;que&nbsp;apagaremos
                          todos&nbsp;os&nbsp;seus&nbsp;dados&nbsp;sem&nbsp;possibilidade&nbsp;de&nbsp;reversão,&nbsp;salvo&nbsp;os&nbsp;casos&nbsp;previstos&nbsp;em
                          lei.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <b
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        Portabilidade
                      </b>
                      <span
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        &nbsp;
                      </span>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Você tem o direito de solicitar, mediante requisição
                          expressa, que a&nbsp;Sony&nbsp;forneça a Você, ou a
                          terceiro que Você escolher, os seus&nbsp;dados
                          pessoais em formato estruturado e interoperável, para
                          transferência a outro fornecedor, desde que não viole
                          a propriedade intelectual ou segredo de negócios da
                          empresa.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <b
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        Eliminação
                      </b>
                      <span
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        &nbsp;
                      </span>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Quando Tratarmos seus Dados Pessoais com base no seu
                          consentimento, Você pode solicitar a eliminação dos
                          dados.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <b
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        Informação sobre o compartilhamento
                      </b>
                      <span
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        &nbsp;
                      </span>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Você tem o direito de saber as entidades públicas e
                          privadas com as quais a&nbsp;Sony&nbsp;faz uso
                          compartilhado de dados. Em todo caso, se Você tiver
                          dúvidas ou quiser maiores detalhes, Você tem o direito
                          de nos solicitar essas informações.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <b>Informação sobre a possibilidade de não consentir</b>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Permite que Você tenha informações claras e completas
                          sobre a possibilidade e as consequências de não
                          fornecer consentimento. O seu consentimento,
                          quando&nbsp;necessário,&nbsp;deve&nbsp;ser&nbsp;livre&nbsp;e&nbsp;informado.&nbsp;Portanto,&nbsp;sempre&nbsp;que&nbsp;pedirmos
                          seu consentimento, Você será livre para negá-lo –
                          ainda que, nesses casos, é possível que tenhamos que
                          limitar nossas entregadas para&nbsp;Você.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <b
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        Revogação do consentimento
                      </b>
                      <span
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        &nbsp;
                      </span>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Você tem o direito de retirar o seu consentimento em
                          relação às atividades&nbsp;de tratamento que se
                          baseiam no consentimento.&nbsp;No&nbsp;entanto, isso
                          não afetará a legalidade de qualquer
                          tratamento&nbsp;realizado anteriormente. Se Você
                          retirar o seu consentimento, talvez não possamos
                          fornecer determinadas entregadas para Você, mas iremos
                          avisá-lo quando isso ocorrer.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td data-align="center" className="has-text-align-center">
                      <b
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        Oposição
                      </b>
                      <span
                        style={{
                          fontSize: "revert",
                          fontFamily: "inherit",
                          color: "initial",
                        }}
                      >
                        &nbsp;
                      </span>
                    </td>
                    <td data-align="center" className="has-text-align-center">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          A&nbsp;lei autoriza o tratamento de dados pessoais
                          mesmo sem o seu consentimento.&nbsp;Caso você não
                          concorde com esse tratamento, em alguns casos, Você
                          poderá se opor a ele, solicitando a interrupção.&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </figure>
            <h1 className="wp-block-heading">&nbsp;</h1>
            <p>
              <span style={{ fontWeight: 400 }}>
                Sempre que você exercer seus direitos, a Sony poderá solicitar
                algumas informações complementares para fins de comprovação da
                sua identidade, buscando impedir fraudes.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Fazemos isso para garantir a sua segurança e sua privacidade.
                Ainda, algumas solicitações podem não ser respondidas de forma
                imediata, mas a Sony se compromete a responder todas as
                requisições em um prazo razoável e sempre em conformidade com a
                legislação aplicável.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Você também pode nos pedir para não enviarmos comunicações de
                marketing e para não utilizar seus dados pessoais na elaboração
                de perfis para fins de marketing direto ou qualquer outro
                beneficiamento com base no seu consentimento. Você pode optar
                por não receber e-mail com boletins informativos e outras
                comunicações de marketing, seguindo as instruções de
                cancelamento fornecidas a você, nesses e-mails. Caso você se
                inscreva para receber mensagens SMS ou MMS, você pode
                cancelá-las respondendo “PARE”. Mensagens de contas operacionais
                não serão afetadas se você cancelar as comunicações de
                marketing.
              </span>
            </p>
            <ol start={5}>
              <li>
                <b> Por quanto tempo os dados pessoais serão armazenados?</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Nós manteremos os dados pessoais dos Usuários do Site somente
                pelo tempo que for{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                necessário para cumprir com as finalidades para as quais os
                coletamos, inclusive para fins de cumprimento de quaisquer
                obrigações legais, contratuais, de prestação de contas ou
                requisição de autoridades competentes.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Todos os dados coletados serão excluídos de nossos servidores
                quando o Usuário assim{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                requisitar ou quando estes não forem mais necessários ou
                relevantes para a Sony, salvo se houver qualquer outra razão
                para a sua manutenção, como eventual obrigação legal de retenção
                de dados, ou necessidade de preservação destes para resguardo de
                direitos da Sony.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Para determinar o período de retenção adequado para os dados
                pessoais, consideramos a quantidade, a natureza e a
                sensibilidade dos dados pessoais, o risco potencial de danos
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                decorrentes do uso não autorizado ou da divulgação de seus dados
                pessoais, a finalidade de processamento dos seus dados pessoais
                e se podemos alcançar tais propósitos através de outros meios, e
                os requisitos legais aplicáveis.
              </span>
            </p>
            <ol start={6}>
              <li>
                <b> Como funciona a Segurança da Informação na Sony</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                A SONY adota todas as providências técnicas e organizacionais
                para proteger os dados{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                pessoais dos Usuários do Site contra perda, uso não autorizado
                ou outros abusos. Os dados serão armazenados em um ambiente
                operacional seguro que não é acessível ao público.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Nós nos esforçamos para proteger a privacidade dos dados
                pessoais que armazenamos, mas infelizmente não podemos garantir
                total segurança. O uso não autorizado de contas, falha de
                hardware ou software e outros fatores podem comprometer a
                segurança dos seus dados pessoais a qualquer momento, por isso,
                nos ajude a manter um ambiente seguro para todos.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Além de adotar boas práticas de segurança em relação a seus
                dados pessoais, caso o Usuário identifique ou tome conhecimento
                de algo que comprometa a segurança dos dados pessoais, entre em
                contato no canais de contato que se encontram abaixo.
              </span>
            </p>
            <ol start={7}>
              <li>
                <b> Transferência Internacional</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Alguns dos nossos Fornecedores podem ter seus servidores
                localizados fora do Brasil, de modo que, para, por exemplo,
                armazenarmos seus dados, precisamos transferi-los para outros
                países. Essas transferências envolvem apenas empresas que
                demonstrem estar em conformidade ou em processo de conformidade
                com as leis de proteção de dados aplicáveis, e mantenham um
                nível de conformidade semelhante ou mais rigoroso do que o
                previsto na legislação brasileira aplicável.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A Sony tem sua sede no Brasil e os dados que coletamos são
                regidos pela lei brasileira. Ao acessar nosso Site, o Usuário
                concorda com o processamento e a transferência de tais dados
                para o Brasil e eventualmente para outros países nos quais
                empresas do mesmo grupo ecônomico da Sony tem sede. Os dados
                pessoais dos Usuários do Site, ao serem transferidos para esses
                países, poderão estar sujeitos à legislação local e as regras
                pertinentes.
              </span>
            </p>
            <ol start={8}>
              <li>
                <b> Como falar com a Sony</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se você acredita que suas informações pessoais foram usadas de
                maneira incompatível com esta Política ou com as suas escolhas
                enquanto titular destes dados, ou, ainda, se você tiver outras
                dúvidas, comentários ou sugestões relacionadas a esta Política,
                você pode entrar em contato com nosso time pelo nosso Site, na
                aba Contatos (
              </span>
              <a href="https://embaixadorcupido.com.br/contatos/">
                <span style={{ fontWeight: 400 }}>
                  https://embaixadorcupido.com.br/contatos/
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>).</span>
            </p>
            <ol start={9}>
              <li>
                <b> Atualizações desta Política</b>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Como estamos sempre buscando melhorar nosso Site, esta Política
                pode passar por{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                atualizações, visando fornecer ao Usuário mais segurança,
                conveniência e melhorar cada vez mais a sua experiência. É por
                isso que recomendamos que o Usuário acesse nossa Política
                periodicamente, para que tenha conhecimento sobre as
                modificações. Para facilitar, indicamos no final do documento a
                data da última atualização. Caso sejam feitas alterações
                relevantes que ensejem novas autorizações dos Usuários, iremos
                publicar essa atualização e solicitar um novo consentimento.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Data da última atualização: 01 de junho de 2021.
              </span>
            </p>
          </article>
        </div>
      </main>
    </Page>
  );
}

export default PrivacyPolicy;
