import React, {Fragment, useEffect, useState} from 'react';
import {Icon, Image, Slider, Progress, Grid} from '../../components';
import defaultImage from '../../assets/images/default.jpg';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as ProfileActions } from '../../store/modules/profile/actions';

import {Link} from 'react-router-dom'
import { MusicList } from '../MusicList';

function ProfileCard(props) {
  const dispatch = useDispatch();
  const profileReducer = useSelector(state => state.profile)

  const [topMusics, setTopMusics] = useState([])
  
  useEffect(() => {
    const included = 'included=musical_preferences';
    dispatch(ProfileActions.loadProfileRequest(included));
  }, []);

  useEffect(() => {
    if(props?.userData?.topMusics) {
      const topMusicsObj = props.userData.topMusics
      .filter(obj1 =>{
        const profileReducerTopMusics = profileReducer.topMusics.map(item => item.uid);
        return profileReducerTopMusics.includes(obj1.uid)
      });
      setTopMusics(topMusicsObj)
    }

  }, [profileReducer])


  return (
    <div className="ProfileCardGusttavo">
      <Grid>
        <Grid.cell className="cell-5_lg">
          <div className="ChooseMusic">
            <div className="choose-music-profile-info">
              <Image
                className="choose-music-profile-avatar"
                src={props.userData.image ? props.userData.image : defaultImage}
              />
            </div>
            <div className="choose-music-profile-info-wrapper">
              <h3>{props.userData.name}, {props.userData.age}</h3>
              <div className="address">{props.userData.address}</div>
              <p className="choose-music-profile-description">
                {props.userData.description}
              </p>
            </div>
          </div>
        </Grid.cell>

        <Grid.cell className="cell-7_lg">
          <MusicList
            title={`O que ${props.userData.name} está curtindo:`}
            subtitle
            header
            star
            data={topMusics}
          />
          {/* <MusicList title='Sua playlist' data={props?.selectedMusicals ?? []} updatePlaylist={props.updatePlaylist}/> */}

        </Grid.cell>
      </Grid>
    </div>

  );
}

export { ProfileCard };
