import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import { Actions as ProfileActions } from '../../../store/modules/profile/actions';
import { Actions as StatesActions } from '../../../store/modules/states/actions';
import { Preferences } from '../../../services/api/prerefences';

import Front from './front';

const Index = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const included = 'included=musical_preferences,city,city.state';
    dispatch(ProfileActions.loadProfileRequest(included));
    dispatch(StatesActions.loadStatesRequest());
  
  }, []);

  const profileReducer = useSelector(state => state.profile)
  const statesReducer = useSelector(state => state.statesbr)
  
  const [disableCities, setDisableCities] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMusicals, setSelectedMusicals] = useState(profileReducer?.topMusics ?? []);

  useEffect(() => {
    setFormData({
      image: profileReducer.image,
      name: profileReducer.name,
      email: profileReducer.email,
      date: profileReducer.birthdate,
      description: profileReducer.description,
      genre: normalizeGenre(profileReducer.genre),
      genreFemale: profileReducer.preferences.some(item => item === 'female'),
      genreMale: profileReducer.preferences.some(item => item === 'male'),
      genreOther: profileReducer.preferences.some(item => item === 'undefined'),
      genreNotBinary: profileReducer.preferences.some(item => item === 'non_binary'),
      city: profileReducer.currentCityId,
      state: profileReducer.currentStateAcronym,

      topArtists: profileReducer.topArtists,
      topMusics: profileReducer.topMusics,
      loadEnd: profileReducer.loadEnd,
    });
    setSelectedMusicals(profileReducer.topMusics)
  }, [profileReducer]);

  const normalizeGenre = (value) => {
    if(value === 'female') return 'm'
    if(value === 'male') return 'h'
    if(value === 'non_binary') return 'nb'
    if(value === 'undefined') return 'o'
  }

  const submit = async frontData => {
    const { name, email, date, genre, city, description, image } = frontData

    let preferences = []
    if(frontData.genreFemale) preferences.push('female')
    if(frontData.genreMale) preferences.push('male')
    if(frontData.genreNotBinary) preferences.push('non_binary')
    if(frontData.genreOther) preferences.push('undefined')

    let normalizedGenre;
    if(genre === 'm') normalizedGenre = 'female'
    if(genre === 'h') normalizedGenre = 'male'
    if(genre === 'o') normalizedGenre = 'undefined'
    if(genre === 'nb') normalizedGenre = 'non_binary'

    dispatch(ProfileActions.updateProfileRequest(
      image,
      name,
      email,
      date,
      normalizedGenre,
      preferences,
      description,
      city
    ))
  }

  const onChangeState = (acronym) => {
    if(acronym === '- Selecionar -') {
      setDisableCities(true)
      return
    }
    const stateSelected = statesReducer.states.find(item => item.attributes.acronym === acronym)
    dispatch(StatesActions.loadCitiesRequest(stateSelected.id))
    setDisableCities(false)
  }

  const normalizeStates = () => {
    const normalized = statesReducer.states.map(item => {
      return { value: item.attributes.acronym, label: item.attributes.name }
    });
    return [{ value: null, label: '- Selecionar -' }, ...normalized]
  }

  const normalizeCities = () => {
    const normalized = statesReducer.cities.map(item => {
      return { value: item.id, label: item.attributes.name }
    });
    return [{ value: null, label: '- Selecionar -' }, ...normalized]
  }

  async function handlePlaylistSaveClick(data) {
    try {

      setIsLoading(true);
      const body = selectedMusicals.map((music, index) => {
        
        return {
            kind: 1,
            uid: music.uid,
            extra_info: JSON.stringify({
              music,
            }),
            youtube_id: music.youtube_id,
            position: index+1
        }
      })
      
      await Preferences.addMusicalPreferences({
        musical_preferences: body,
        update: true
      })

      
      toast.success('Playlist salva com sucesso.');
      
    } catch (error) {
      toast.error('Erro ao salvar Playlist.');
    } finally {
      setIsLoading(false);
    }
  }

  function handleUpdatePlaylist(musics) {
    setSelectedMusicals(musics)
  }

  useEffect(() => {
    if(!statesReducer.states.length) return;

    const stateSelected = statesReducer.states.find(
      item => item.attributes.acronym === profileReducer.currentStateAcronym
    )

    if(!stateSelected) return;

    dispatch(StatesActions.loadCitiesRequest(stateSelected.id))
  
  }, [profileReducer.currentStateAcronym, statesReducer.states]);

  const checkData = () => {
    if(formData?.email || formData?.loadEnd) return true
    return false
  }

  return (
    <Fragment>
      {checkData() &&
        <Front
          formData={formData}
          setFormData={setFormData}

          onSaveClick={frontData => submit(frontData)}

          statesOptions={normalizeStates()}
          onChangeState={onChangeState}

          loadingForm={isLoading || profileReducer.updatingProfile}

          citiesOptions={normalizeCities()}
          disableCities={disableCities}

          genreOptions={[
            {value:'-',label:'- selecionar -'},
            {value:'m',label:'Mulher'},
            {value:'h',label:'Homem'},
            {value:'nb',label:'Não-binário'},
            {value:'o',label:'Outro'},
          ]}
          selectedMusicals={selectedMusicals}
          onPlaylistSaveClick={handlePlaylistSaveClick}
          updatePlaylist={handleUpdatePlaylist}
        />
      }
      {/* {props?.teste === 'teste' ? 
        <Front
          teste={props?.teste}
          formData={ formData}
          setFormData={props?.teste !== 'teste' ? setFormData : ''}

          onSaveClick={props?.teste !== 'teste' ? frontData => submit(frontData) : ''}

          statesOptions={props?.teste !== 'teste' ? normalizeStates() : ''}
          onChangeState={props?.teste !== 'teste' ? onChangeState : ''}

          loadingForm={props?.teste !== 'teste' ? profileReducer.updatingProfile : ''}

          citiesOptions={props?.teste !== 'teste' ? normalizeCities() : ''}
          disableCities={props?.teste !== 'teste' ? disableCities : ''}

          genreOptions={[
            {value:'-',label:'- selecionar -'},
            {value:'m',label:'Mulher'},
            {value:'h',label:'Homem'},
            {value:'nb',label:'Não-binário'},
            {value:'o',label:'Outro'},
          ]}
        /> 
      : 
        
        } */}
    </Fragment>
  )
}

export default Index;
