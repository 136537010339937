import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import dateToAge from '../../utils/dateToAge';
import { useInterval } from '../../utils/useInterval';
import defaultImage from "../../assets/images/default.jpg";

import Front from './front';
import { Actions as AuthActions } from '../../store/modules/auth/actions';
import { Actions as ProfileActions } from '../../store/modules/profile/actions';
import { Actions as CampaignActions } from '../../store/modules/campaigns/actions';

const Index = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileReducer = useSelector(state => state.profile)
  const { currentCampaign } = useSelector(state => state.auth)

  const campaignReducer = useSelector(state => state.campaigns)
  
  useEffect(() => {
    const included = 'included=city,city.state';
    dispatch(ProfileActions.loadProfileRequest(included));
    dispatch(CampaignActions.loadCurrerntCampaignRequest(currentCampaign))
    dispatch(CampaignActions.checkCampaignStatus())
  
  }, []);
    
  useInterval(() => {
    dispatch(ProfileActions.loadMessagesResumeRequest())
  }, 20000);

  const logout = () => {
    dispatch(AuthActions.logout())
    history.push(`/${currentCampaign}`);
  }

  const deleteAccount = () => {
    dispatch(ProfileActions.deleteAccountRequest())
  }
  
  const getAddrress = () => {
    return `${profileReducer.currentCityName} - ${profileReducer.currentStateAcronym}`
  }

  return (
    <Front
      teste={props?.teste ? props.teste: undefined}
      userData={{
        name: profileReducer.name ,
        email:  profileReducer.email ,
        age:  dateToAge(profileReducer.birthdate),
        address:  getAddrress() ,
        image:  profileReducer.image || defaultImage,
      }}

      onOkClick={() => {history.push("/")}}
      onLogout={logout}
      onDeleteAccount={deleteAccount}

      faqDownloadUrl={campaignReducer?.currentCampaign?.attributes?.doc_faq}
    />
    
  )
}

export default Index;
