import React, { useEffect, useState } from 'react';

import ActionCable from 'actioncable';
import { useInterval } from '../../utils/useInterval';
import history from '../../services/history';

import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../store/modules/matches/actions';

import { Actions as ProfileActions } from '../../store/modules/profile/actions';
import { Actions as CampaignActions } from '../../store/modules/campaigns/actions';

import Front from './front';

const Index = (props) => {
  const [roomId, setRoomId] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.loadMatchesRequest())
    dispatch(ProfileActions.loadMessagesResumeRequest())
    dispatch(CampaignActions.checkCampaignStatus())
  }, []);

  useInterval(() => {
    dispatch(ProfileActions.loadMessagesResumeRequest())
  }, 20000);

  const { matches, finishLoadMatches, currentChat, deleteMatchOk, loadingMoreMessages } = useSelector(state => state.matches)
  const { token, profile } = useSelector(state => state.auth)


  const normalizeMatches = () => {
    const { data } = profile

    return matches.map(item => {
      const user = item.users.find(item => parseInt(item.id) !== parseInt(data.id))      
      const { name, avatar, city } = user
      const { state } = city

      return {
        id: item.id,
        name: name,
        image: avatar,
        address: `${city.name} - ${state.acronym}`,
        unread: item.messages.unread,
      }
    })
  }

  useEffect(() => {
    if(!roomId) return
    
    setCurrentPage(1)

    dispatch(Actions.switchCurrentChat(roomId));

    dispatch(Actions.loadMessagesRequest(roomId, 1));
    
    dispatch(Actions.updateUnreadMessages(roomId, 0));
  }, [roomId]);

  useEffect(() => {
    if(!matches.length || finishLoadMatches === false) return;
    
    setRoomId(matches[0].id)
  }, [finishLoadMatches]);

  const cable = ActionCable.createConsumer(`${process.env.REACT_APP_SOCKET_CABLE}/cable?token=${token}`)

  useEffect(() => {

    const channel = cable.subscriptions.create(
      {
        channel: 'ChatChannel',
        room_id: roomId,
      },
      {
        received: (data) => {
          receiveMessage(data)
        }
      }
    );

    return () => {
      channel.unsubscribe();
    }
  }, [roomId]);

  const messagesScrollTop = (scrollTop) => {
    if (scrollTop == 0) {
      setCurrentPage(prev => prev + 1)
      dispatch(Actions.loadMoreMessagesRequest(roomId, currentPage + 1))
    }
  }

  const deleteMatch = (id) => {
    dispatch(Actions.deleteMatchRequest(id))
    dispatch(Actions.loadMatchesRequest())
  }

  const sendMessage = (message) => {
    dispatch(Actions.sendMessageRequest(roomId, message))
  }

  const receiveMessage = (messageData) => {
    dispatch(Actions.receiveMessage(messageData))
  }

  return (
      <Front
        cable={cable}

        roomId={roomId}

        messagesScrollTop={messagesScrollTop}

        chatList={normalizeMatches()}

        currentChat={currentChat} // @valmy aqui Tem q mandar TRUE a primeira vez que carrega
        loadingForm={loadingMoreMessages}
      
        onChangeChat ={(id) => setRoomId(id)}

        onSendMessage={(message) => sendMessage(message)}
        onReceiveMessage={(message) => receiveMessage(message)}

        onDeleteMatch={deleteMatch}

        deleteMatchOk={deleteMatchOk}

        onGotoMatch={() => history.push('/app/match')}
      />
  )
}

export default Index;
