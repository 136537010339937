import React, { useState, useEffect } from "react";
import {
  Page,
  Container,
  Image,
  Grid,
} from "../../components";
import { Button } from '../../components';
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';

import { MusicList } from "../../components/MusicList";

import dateToAge from "../../utils/dateToAge";

import defaultImage from "../../assets/images/default.jpg";

function Front(props) {

  const profileReducer = useSelector(state => state.profile)

  return (
    <Page name="choose" loading={!profileReducer?.loadEnd || props.isLoading }>
      {/* {userData && ( */}
        <Container center>
          <Grid>
            <Grid.cell className="cell-5_lg">
              <div className="ChooseMusic">
                <div className="choose-music-profile-info">
                  <Image
                    className="choose-music-profile-avatar"
                    src={profileReducer?.image ? profileReducer.image : defaultImage}
                  />
                </div>
                <div className="choose-music-profile-info-wrapper">
                  <h3>{profileReducer?.name}, {profileReducer?.birthdate ? dateToAge(profileReducer.birthdate) : ''}</h3>
                  <div className="address">
                    {`${profileReducer.currentCityName} - ${profileReducer.currentStateAcronym}`}
                  </div>
                  <p className="choose-music-profile-description">
                    {profileReducer.description}
                  </p>
                </div>
              </div>
            </Grid.cell>

            <Grid.cell  className="cell-1_lg"> </Grid.cell>

            <Grid.cell className="cell-6_lg">
              {/* <MusicList /> */}
              <MusicList data={props?.selectedMusicals ?? []} updatePlaylist={props.updatePlaylist}/>

            </Grid.cell>
          </Grid>
          <div className="choose-music-buttons">
            {/* <Button className="back" link={Link} to="/app/profile">Voltar para perfil</Button> */}
            <Button className="save" onClick={props.onPlaylistSaveClick}>Publicar playlist</Button>
          </div>
        </Container>
      {/* )} */}
    </Page> 
  );
}

export default Front;
